import styled from "styled-components";

export const H10 = styled.div`
  width: 100%;
  height: 10px;
`;

export const H20 = styled.div`
  width: 100%;
  height: 20px;
`;

export const H30 = styled.div`
  width: 100%;
  height: 30px;
`;

export const H40 = styled.div`
  width: 100%;
  height: 40px;
`;

export const H50 = styled.div`
  width: 100%;
  height: 50px;
`;

export const H60 = styled.div`
  width: 100%;
  height: 60px;
`;

export const H70 = styled.div`
  width: 100%;
  height: 70px;
`;

export const H80 = styled.div`
  width: 100%;
  height: 80px;
`;

export const H90 = styled.div`
  width: 100%;
  height: 90px;
`;

export const H100 = styled.div`
  width: 100%;
  height: 100px;
`;