const whiskyList = [
  {
    category : "Scotch",
    drinks: [
      {
        name: "Red Label",
        price: "5.00"
      },
      {
        name: "Laphroig 10 anni",
        price: "8.00"
      },
      {
        name: "Talisker 10 anni",
        price: "9.00"
      },
      {
        name: "Oban 14 anni",
        price: "8.00"
      },
      {
        name: "Lagavulin 10 anni",
        price: "10.00"
      },
    ]
  },
  {
    category : "Japan",
    drinks: [
      {
        name: "Kensei",
        price: "8.00"
      },
      {
        name: "Nikka from Barrel",
        price: "10.00"
      },
      {
        name: "Taketsuru pure malt",
        price: "15.00"
      },
      {
        name: "Tokinoka white oak",
        price: "9.00"
      },
      {
        name: "Tokinoka",
        price: "9.00"
      },
    ]
  },
  {
    category : "Bourbon",
    drinks: [
      {
        name: "Wild Turkey Bourbon",
        price: "5.00"
      },
      {
        name: "Wild Turkey 101",
        price: "7.00"
      },
      {
        name: "Wild Turkey Rare Breed",
        price: "8.00"
      },
      {
        name: "Wild Turkey Russel 10 anni",
        price: "8.00"
      },
      {
        name: "Extra Brook 99",
        price: "8.00"
      },
    ]
  },
  {
    category : "Jack",
    drinks: [
      {
        name: "Tennesse",
        price: "5.00"
      },
      {
        name: "Fire",
        price: "5.00"
      },
      {
        name: "Honey",
        price: "5.00"
      },
      {
        name: "Single Barrel",
        price: "8.00"
      },
      {
        name: "Gentlemen",
        price: "8.00"
      },
    ]
  },
];

export default whiskyList;