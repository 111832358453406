import styled from "styled-components";

export const SFooter = styled.footer`
  width: 100%;
  color: #c5c5c5;
  background-color: #000000;
`;

export const Sh3 = styled.h3`
  color: #fff;
`;

export const Sh4 = styled.h4`
  color: #fff;
`;

export const Sh5 = styled.h5`
  color: #fff;
`;

export const Sh6 = styled.h6`
  color: #fff;
`;

export const Sul = styled.ul`
  margin: 0px;
  padding: 0px;
`;

export const Sli = styled.li`
  margin-top: 10px;
  list-style-type: none;
`;

export const SUnder = styled.div`
  padding: 20px 0px;
  background-color: #070707;
  text-align: center;
`;