const cocktailPremiumList = [
  {
    name: "Conte Negroni",
    ingredients: "(Bitter Campari, Carpano Antica Formula, gin Bombay)",
  },
  {
    name: "Americano Royale",
    ingredients: "(Bitter Campari, Carpano Antica Formula, Soda)",
  },
  {
    name: "Americano Siciliano",
    ingredients: "(Etna Vermouth, Etna Bitter, Soda)",
  },
  {
    name: "Negroni Siciliano",
    ingredients: "(Etna Bitter, Etna Vermouth, Etna Gin)",
  },
  {
    name: "Over Fashioned",
    ingredients: "(Wild Turkey Rare Breed, Angostura Bitter, Zucchero)",
  },
  {
    name: "Mito Invecchiato",
    ingredients: "(Bitter Campari, Vermouth Rosso, invecchiati in botte di rovere)",
  },
  {
    name: "Choconegroni",
    ingredients: "(Gin infuso al cacao home made, Vermouth alla pera e cannella home made, Bitter martini rosso reserva)",
  },
];

export default cocktailPremiumList;