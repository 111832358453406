import React from 'react';
import { useTranslation } from "react-i18next";

import * as Styles from './styles';
import * as Divider from '../../styles/dividerStyles';

import cocktailVintageList from '../../menu/cocktailVintageList';
import cocktailPremiumList from '../../menu/cocktailPremiumList';
import cocktailClassicList from '../../menu/cocktailClassicList';
import cocktailClassicSecondList from '../../menu/cocktailClassicSecondList';
import cocktailIndustrialSipsList from '../../menu/industrialSipsList';
import spritzList from '../../menu/spritzList';
import cocktailAnalcoholicList from '../../menu/cocktailAnalcoholicList';

export default function PageCocktail() {
  const { t } = useTranslation();

  return (
    <Styles.PageMenu id={t('menu-title_cocktail_tag')}>
      <Styles.H2>{t('menu-title_cocktail')}</Styles.H2>

      <Styles.H3>{t('menu-cocktail_vintage')}</Styles.H3>
      { cocktailVintageList.map((cocktail, i) =>
        <Row key={i} name={cocktail.name} ingredients={cocktail.ingredients} price={cocktail.price}/>
      )}

      <Divider.H20/>

      <Styles.H3>{t('menu-cocktail_premium')}</Styles.H3>
      { cocktailPremiumList.map((cocktail, i) =>
        <Row key={i} name={cocktail.name} ingredients={cocktail.ingredients} price={"8.00"}/>
      )}

      <Divider.H20/>

      <Styles.H3>Industrial Sips (signature cocktail)</Styles.H3>
      { cocktailIndustrialSipsList.map((cocktail, i) =>
        <Row key={i} name={cocktail.name} ingredients={cocktail.ingredients} price={"8.00"}/>
      )}

      <Divider.H20/>

      <Styles.H3>{t('menu-cocktail_classic')}</Styles.H3>
      { cocktailClassicList.map((cocktail, i) =>
        <Row key={i} name={cocktail.name} ingredients={cocktail.ingredients} price={"6.00"}/>
      )}

      <Divider.H20/>

      <Styles.H3>{t('menu-cocktail_classic')}</Styles.H3>
      { cocktailClassicSecondList.map((cocktail, i) =>
        <Row key={i} name={cocktail.name} ingredients={cocktail.ingredients} price={"7.00"}/>
      )}

      <Divider.H20/>

      <Styles.H3>{t('menu-cocktail_spritz')}</Styles.H3>
      { spritzList.map((cocktail, i) =>
        <Row key={i} name={cocktail.name} ingredients={cocktail.ingredients} price={"5.00"}/>
      )}

      <Divider.H20/>

      <Styles.H3>{t('menu-cocktail_analcoholic')}</Styles.H3>
      { cocktailAnalcoholicList.map((cocktail, i) =>
        <Row key={i} name={cocktail.name} ingredients={cocktail.ingredients} price={"5.00"}/>
      )}
    </Styles.PageMenu>
  );
}

const Row = ({name, price, ingredients}) => {
  return (
    <Styles.Row>
      <Styles.Name>{name}</Styles.Name>
      <Styles.Price>{price} €</Styles.Price>
      <Styles.Ingredients>{ingredients}</Styles.Ingredients>
    </Styles.Row>
  );
}