export const lightTheme = {
  bg: "rgb(245,245,245)",
  bg2: "rgb(255,255,255)",
  bg3: "rgb(230,230,230)",
  bgAlpha: "rgba(250,250,250,.3)",
  title: "rgb(34, 34, 34)",
  text: "rgb(94, 94, 94)",
  primary: "rgb(52, 131, 235)",

  headerBackground: "rgb(255,255,255)",

  tableBgHead: "rgb(255,255,255)",
  tableBgTrOdd: "rgb(230,230,230)",
  tableBgTrEven: "rgb(220,220,220)",

  btnBgPrimary: "rgb(0, 0, 0)",
  btnTextPrimary: "rgb(255, 255, 255)",
  btnBorderPrimary: "rgb(17, 17, 17)",
  btnBgPrimaryHover: "rgb(20, 20, 20)",
  btnBorderPrimaryHover: "rgb(17, 17, 17)",

  btnBgGreen: "rgb(81, 195, 86)",
  btnTextGreen: "rgb(0, 0, 0)",
  btnBorderGreen: "rgb(76, 174, 76)",
  btnBgGreenHover: "rgb(112, 204, 116)",
  btnBorderGreenHover: "rgb(96, 194, 96)",

  btnBgRed: "rgb(228, 112, 121)",
  btnTextRed: "rgb(255, 255, 255)",
  btnBorderRed: "rgb(217, 55, 67)",
  btnBgRedHover: "rgb(248, 132, 141)",
  btnBorderRedHover: "rgb(237, 75, 87)",
};

export const darkTheme = {
  bg: "rgb(30,30,30)",
  bg2: "rgb(15,15,15)",
  bg3: "rgb(50,50,50)",
  bgAlpha: "rgba(0,0,0,.3)",
  title: "rgb(210,210,210)",
  text: "rgb(210,210,210)",
  primary: "rgb(52, 131, 235)",

  headerBackground: "rgb(15,15,15)",

  tableBgHead: "rgb(15,15,15)",
  tableBgTrOdd: "rgb(25,25,25)",
  tableBgTrEven: "rgb(35,35,35)",

  btnBgPrimary: "rgb(50,50,50)",
  btnBorderPrimary: "rgb(15,15,15)",
  btnBgPrimaryHover: "rgb(40,40,40)",
  btnBorderPrimaryHover: "rgb(25,25,25)",

  btnBgGreen: "rgb(67, 154, 71)",
  btnBorderGreen: "rgb(61, 139, 61)",
  btnBgGreenHover: "rgb(57, 144, 61)",
  btnBorderGreenHover: "rgb(51, 129, 51)",

  btnBgRed: "rgb(183, 35, 47)",
  btnBorderRed: "rgb(183, 35, 47)",
  btnBgRedHover: "rgb(163, 15, 27)",
  btnBorderRedHover: "rgb(168, 19, 30)",
};