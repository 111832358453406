const cocktailClassicSecondList = [
  {
    name : "Mai Tai",
    ingredients: "(Kingston White Rum, Kingston Gold Rum, Succo di limone, Orange Curaçao, Orzata)",
  },
  {
    name : "Cocktail Martini",
    ingredients: "(Gin Bikens, Vermouth Dry)",
  },
];

export default cocktailClassicSecondList;