import React from 'react';
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import { BrowserView, MobileView } from 'react-device-detect';

import Head from '../../layouts/head';
import Footer from '../../components/footer';

import PageWine from './wines';
import PageChampagne from './champagne';
import PageBeer from './beer';
import PageCocktail from './cocktail';
import PageDistillates from './distillates';
import PageFoods from './foods';

import * as Styles from './styles';
import * as Divider from '../../styles/dividerStyles';

import logo from '../../assets/img/logo.jpg';
import { BsFacebook, BsInstagram } from 'react-icons/bs';

export default function Menu() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('menu-title')}
        description={t('menu-description')}
        keyword={t('menu-keyword')}
        path='menu'
      />
      <Main/>
    </>
  );
}

const Main = () => {
  const { t } = useTranslation();

  return (
    <Styles.Background style={{backgroundImage: `url('/assets/img/bg-menu.webp')`}}>
      <LanguagesButtons/>
      <SocialButtons/>

      <MenuPages/>

      <Styles.BtnToIndex smooth to="#index">
        {t('menu-to_index')}
      </Styles.BtnToIndex>
    </Styles.Background>
  );
}

const LanguagesButtons = () => {
  function handleChangeLanguage(newCodeLang){
    i18next.changeLanguage(newCodeLang);
  }

  return (
    <Styles.ContainerBtnLang>
      <Styles.LangBtn onClick={() => handleChangeLanguage("it")}>
        <Styles.Flag src="/assets/img/flag/it.svg" alt="Italiano"/>
      </Styles.LangBtn>
      <Styles.LangBtn onClick={() => handleChangeLanguage("en")}>
        <Styles.Flag src="/assets/img/flag/uk.svg" alt="English"/>
      </Styles.LangBtn>
      <Styles.LangBtn onClick={() => handleChangeLanguage("fr")}>
        <Styles.Flag src="/assets/img/flag/fr.svg" alt="Francais"/>
      </Styles.LangBtn>

      <Styles.LangBtn onClick={() => handleChangeLanguage("de")}>
        <Styles.Flag src="/assets/img/flag/de.svg" alt="German"/>
      </Styles.LangBtn>
      <Styles.LangBtn onClick={() => handleChangeLanguage("es")}>
        <Styles.Flag src="/assets/img/flag/es.svg" alt="Spanish"/>
      </Styles.LangBtn>
      <Styles.LangBtn onClick={() => handleChangeLanguage("se")}>
        <Styles.Flag src="/assets/img/flag/se.svg" alt="Swedish"/>
      </Styles.LangBtn>
    </Styles.ContainerBtnLang>
  );
}

const SocialButtons = () => {
  return (
    <>
      <BrowserView>
        <Styles.ContainerBtnSocial>
          <Styles.IconBtn href={process.env.REACT_APP_FACEBOOK} target="_blank" rel="noopener noreferrer">
            <BsFacebook style={{position: "relative", top: "2px"}}/>
          </Styles.IconBtn>
          <Styles.IconBtn href={process.env.REACT_APP_INSTAGRAM} target="_blank" rel="noopener noreferrer">
            <BsInstagram style={{position: "relative", top: "2px"}}/>
          </Styles.IconBtn>
        </Styles.ContainerBtnSocial>
      </BrowserView>

      <MobileView>
        <Styles.ContainerBtnSocial>
          <Styles.IconBtn href={process.env.REACT_APP_FACEBOOK_ON_MOBILE} target="_blank" rel="noopener noreferrer">
            <BsFacebook style={{position: "relative", top: "2px"}}/>
          </Styles.IconBtn>
          <Styles.IconBtn href={process.env.REACT_APP_INSTAGRAM_ON_MOBILE} target="_blank" rel="noopener noreferrer">
            <BsInstagram style={{position: "relative", top: "2px"}}/>
          </Styles.IconBtn>
        </Styles.ContainerBtnSocial>
      </MobileView>
    </>
  );
}

const MenuPages = () => {
  return (
    <Styles.Main>
      <Styles.ContainerMenu>
        <Index/>
        <Styles.MenuPageDivider/>

        <PageWine/>
        <Styles.MenuPageDivider/>

        <PageChampagne/>
        <Styles.MenuPageDivider/>

        <PageBeer/>
        <Styles.MenuPageDivider/>

        <PageCocktail/>
        <Styles.MenuPageDivider/>

        <PageDistillates/>
        <Styles.MenuPageDivider/>

        <PageFoods/>
        <Styles.MenuPageDivider/>

        <PageAllergens/>
        <Styles.MenuPageDivider/>

        <Footer/>
      </Styles.ContainerMenu>
    </Styles.Main>
  )
}

const Index = () => {
  const { t } = useTranslation();

  return (
    <Styles.PageMenu id="index">
      <div style={{textAlign: "center"}}>
        <Styles.Logo src={logo} alt="Logo skalunata cafe retro"/>
      </div>
      <Styles.H1>Menù</Styles.H1>

      <Divider.H30/>

      <Styles.ContainerIndex>
        <Card img="menu-wine-1" title={t('menu-title_wine')} to={`#${t('menu-title_wine_tag')}`}/>

        <Card img="menu-champagne-1" title={t('menu-title_champagne')} to={`#${t('menu-title_champagne_tag')}`}/>

        <Card img="menu-beer-2" title={t('menu-title_beer')} to={`#${t('menu-title_beer_tag')}`}/>

        <Card img="menu-cocktail-1" title={t('menu-title_cocktail')} to={`#${t('menu-title_cocktail_tag')}`}/>

        <Card img="menu-distillate-1" title={t('menu-title_distillates')} to={`#${t('menu-title_distillates_tag')}`}/>

        <Card img="menu-food-1" title={t('menu-title_food')} to={`#${t('menu-title_food_tag')}`}/>
      </Styles.ContainerIndex>
    </Styles.PageMenu>
  );
}

const PageAllergens = () => {
  const { t, ready } = useTranslation();

  if (!ready) return "loading translations...";

  const listAllergens = t('menu-list_allergens', { returnObjects: true });

  return (
    <Styles.PageMenu>
      <Styles.H4>{t('menu-title_allergens')}</Styles.H4>

      <Styles.UlAllergenes style={{listStyleType: "decimal"}}>
        { listAllergens.map((item, i) =>
          <LiAllergens key={i} name={item.name} description={item.description} points={item.points}/>
        )}
      </Styles.UlAllergenes>

      <Divider.H30/>
      <small>{t('menu-allergens_info')}</small>
    </Styles.PageMenu>
  );
}

const LiAllergens = ({name, description, points}) => {
  return(
    <li>
      <strong>{name}</strong> {description} <br/>
      { points?.length &&
        <Styles.UlAllergenes style={{listStyleType: "decimal"}}>
          { points.map((point, i) =>
            <li key={i}>{point.point}</li>
          )}
        </Styles.UlAllergenes>
      }
    </li>
  );
}

const Card = ({img, title, to}) => {
  return (
    <Styles.IndexCard smooth to={to}>
      <Styles.IndexCardImg style={{backgroundImage: `url('/assets/img/${img}.webp')`}}>
        <Styles.IndexCardTitle>{title}</Styles.IndexCardTitle>
      </Styles.IndexCardImg>
    </Styles.IndexCard>
  );
}