const whiskyList = [
  {
    name: "London n°3",
    price: "8.00"
  },
  {
    name: "Beefeater",
    price: "6.00"
  },
  {
    name: "Beefeater 24",
    price: "7.00"
  },
  {
    name: "Bombay Dry",
    price: "6.00"
  },
  {
    name: "Bombay Saphire",
    price: "6.00"
  },
  {
    name: "Bombay Star",
    price: "8.00"
  },
  {
    name: "Tanqueray",
    price: "6.00"
  },
  {
    name: "Tanqueray n°10",
    price: "10.00"
  },
  {
    name: "Klima Gold (Finger lime)",
    price: "8.00"
  },
  {
    name: "Klima Artigianale (zenzero)",
    price: "8.00"
  },
  {
    name: "Cinghiardo",
    price: "8.00"
  },
  {
    name: "Moon Black",
    price: "9.00"
  },
  {
    name: "Moon Smoked",
    price: "9.00"
  },
  {
    name: "Hendrix",
    price: "9.00"
  },
  {
    name: "Gin Mare",
    price: "9.00"
  },
  {
    name: "Gin Mare Capri",
    price: "10.00"
  },
  {
    name: "Bond St",
    price: "8.00"
  },
  {
    name: "Etna",
    price: "7.00"
  },
  {
    name: "Baruni Pompelmo",
    price: "7.00"
  },
  {
    name: "Baruni Limone",
    price: "7.00"
  },
  {
    name: "Portofino",
    price: "14.00"
  },
  {
    name: "Monkey 47",
    price: "12.00"
  },
  {
    name: "Roku",
    price: "8.00"
  },
  {
    name: "The Botanist",
    price: "10.00"
  },
  {
    name: "Ionico",
    price: "8.00"
  },
  {
    name: "Nordes",
    price: "8.00"
  },
  {
    name: "Malfy",
    price: "7.00"
  },
  {
    name: "Malfy Rosè",
    price: "8.00"
  },
  {
    name: "Gin del Professore Gentlemen",
    price: "8.00"
  },
  {
    name: "Gin del Professore madame",
    price: "8.00"
  },
  {
    name: "Gin del Professore Crocodrile",
    price: "8.00"
  },
  {
    name: "Bikens",
    price: "6.00"
  },
];

export default whiskyList;