import React from 'react';
import { useTranslation } from "react-i18next";

import * as Styles from './styles';

import wineList from '../../menu/wineList';

export default function PageWines() {
  const { t } = useTranslation();

  return (
    <Styles.PageMenu id={t('menu-title_wine_tag')}>
      <Styles.H2>{t('menu-title_wine')}</Styles.H2>

      { wineList.map((item, i) =>
        <Brand key={i} name={item.brand} list={item.list}/>
      )}
    </Styles.PageMenu>
  );
}

const Brand = ({name, list}) => {
  return (
    <>
      <Styles.H3>{name}</Styles.H3>
      { list.map((item, i) =>
        <Category key={i} title={item.category} list={item.wines}/>
      )}
    </>
  );
}

const Category = ({title, list}) => {
  return (
    <>
      <Styles.H4>{title}</Styles.H4>

      { list.map((wine, i) =>
        <Wine key={i} wine={wine}/>
      )}
    </>
  );
}

const Wine = ({wine}) => {
  return (
    <Styles.Row>
      <Styles.Name>{wine.name}</Styles.Name>
      <Styles.Price>
        <>{wine.bottle} € </> {wine.glass && <>{wine.glass} €</>}
      </Styles.Price>
    </Styles.Row>
  );
}