import React from 'react';
import { useTranslation } from "react-i18next";

import * as Styles from './styles';
import * as Divider from '../../styles/dividerStyles';

import brandyList from '../../menu/brandyList';
import whiskyList from '../../menu/whiskyList';
import ginList from '../../menu/ginList';
import vodkaList from '../../menu/vodkaList';

export default function PageDistillates() {
  const { t } = useTranslation();

  return (
    <Styles.PageMenu id={t('menu-title_distillates_tag')}>
      <Styles.H2>{t('menu-title_distillates')}</Styles.H2>

      <Styles.H3>Grappa</Styles.H3>
      { brandyList.map((item, i) =>
        <Distillates key={i} category={item.category} list={item.drinks}/>
      )}

      <Divider.H20/>

      <Styles.H3>Whisky</Styles.H3>
      { whiskyList.map((item, i) =>
        <Distillates key={i} category={item.category} list={item.drinks}/>
      )}

      <Divider.H20/>

      <Styles.H3>Gin</Styles.H3>
      { ginList.map((drink, i) =>
        <Row key={i} name={drink.name} price={drink.price}/>
      )}

      <Divider.H20/>

      <Styles.H3>Vodka</Styles.H3>
      { vodkaList.map((drink, i) =>
        <Row key={i} name={drink.name} price={drink.price}/>
      )}
    </Styles.PageMenu>
  );
}

const Distillates = ({category, list}) => {
  return (
    <>
      <Styles.H4>{category}</Styles.H4>
      { list.map((drink, i) =>
        <Row key={i} name={drink.name} price={drink.price}/>
      )}
    </>
  );
}

const Row = ({name, price}) => {
  return (
    <Styles.Row>
      <Styles.Name>{name}</Styles.Name>
      <Styles.Price>{price} €</Styles.Price>
    </Styles.Row>
  );
}