import React from 'react';

import { SFooter, Sh6, SUnder } from "./styles"
import * as Divider from '../../styles/dividerStyles';

export default function Footer() {
  return (
    <SFooter>
      <Divider.H20/>
      <div className="container">
        <div className='text-center'>
          <Divider.H20/>

          <Sh6>Sede</Sh6>
          <Divider.H10/>
          <a className='m-l-15' href={process.env.REACT_APP_GOOGLE_MAPS} target="_blank" rel="noopener noreferrer">
            {process.env.REACT_APP_CITY_ADDRESS}, {process.env.REACT_APP_ZIP} {process.env.REACT_APP_CITY} ({process.env.REACT_APP_STATE})
          </a>

          <Divider.H20/>

          <Sh6>Telefono</Sh6>
          <Divider.H10/>
          <a className='m-l-15' href={`tel:` + process.env.REACT_APP_CONTACT_PHONE}>{process.env.REACT_APP_CONTACT_PHONE}</a>

          <Divider.H20/>

          <Sh6>P.IVA</Sh6>
          <Divider.H10/>
          <div className="m-l-15">{process.env.REACT_APP_PIVA}</div>
        </div>
      </div>

      <Divider.H40/>

      <SUnder>
        © 2023 All Rights Reserved
      </SUnder>
    </SFooter>
  );

}