import React from 'react';
import { useTranslation } from "react-i18next";

import * as Styles from './styles';

import * as champagne from '../../menu/champagneList';

export default function PageChampagne() {
  const { t } = useTranslation();

  return (
    <Styles.PageMenu id={t('menu-title_champagne_tag')}>
      <Styles.H2>{t('menu-title_champagne')}</Styles.H2>

      { champagne.champagneList.map((item, i) =>
        <Row key={i} name={item.name} price={item.price}/>
      )}

      { champagne.champagneBrandList.map((item, i) =>
        <Brand key={i} title={item.brand} list={item.wines}/>
      )}
    </Styles.PageMenu>
  );
}

const Brand = ({title, list}) => {
  return (
    <>
      <Styles.H3>{title}</Styles.H3>
      { list.map((wine, i) =>
        <Row key={i} name={wine.name} price={wine.bottle}/>
      )}
    </>
  )
}

const Row = ({name, price}) => {
  return (
    <Styles.Row>
      <Styles.Name>{name}</Styles.Name>
      <Styles.Price>{price} €</Styles.Price>
    </Styles.Row>
  );
}