import cookies from 'js-cookie';
import languages from '../config/languages';

const DEFAULT_LANGUAGE = 'it';

function getLangCodeFromUrl(){
  const path = window.location.pathname.split("/");
  return languages.includes(path[1]) ? path[1] : DEFAULT_LANGUAGE;
}

function documentLangCode() {
  const codeLang = document.documentElement.lang;
  return languages.includes(codeLang) ? codeLang : DEFAULT_LANGUAGE;
}

function getLangCode(){
  return cookies.get('i18next') || DEFAULT_LANGUAGE;
}

export {
  getLangCodeFromUrl,
  documentLangCode,
  getLangCode
}