const cocktailClassicList = [
  {
    name : "Negroni",
    ingredients: "(Bitter Campari, Carpano rosso, gin Bikens)",
  },
  {
    name : "Americano",
    ingredients: "(Bitter Campari, Carpano rosso, Soda)",
  },
  {
    name : "Boulevardier",
    ingredients: "(Bitter Campari, Carpano rosso, Wild Turkey Rye)",
  },
  {
    name : "Aviation",
    ingredients: "(Bikens gin, Maraschino, succo di limone, Liquore di violette)",
  },
  {
    name : "Black Russian",
    ingredients: "(Vodka Skyy, Kalua)",
  },
  {
    name : "Blood and Sand",
    ingredients: "(Scotch Wiskey, succo di arancia rossa, Sweet vermouth, Cherry Brandy)",
  },
  {
    name : "Bloody Mary",
    ingredients: "(Vodka skyy, succo di pomodoro, succo di limone, sale al sedano, Tabasco, Worcestershire)",
  },
  {
    name : "Cosmopolitan",
    ingredients: "(Vodka Skyy, succo di limone, triple seec, succo di cranberry)",
  },
  {
    name : "Manhattan",
    ingredients: "(Wild Turkey Rye, Carpano rosso, Angostura Bitter)",
  },
  {
    name : "Vesper Martini",
    ingredients: "(Gin Bikens, Vodka Skyy, Vermout Dry)",
  },
  {
    name : "Aloaska",
    ingredients: "(Gin Bikens, Chartreuse Gialla, Orange Bitter)",
  },
  {
    name : "Clover Club",
    ingredients: "(Gin Bikens, Succo di Limone, Purea di Lamponi, Albume)",
  },
  {
    name : "God Father",
    ingredients: "(Bourbon Whiskey, Amaretto)",
  },
  {
    name : "Harvey Wallbanger",
    ingredients: "(Vodka Skyy, Galliano, Succo di Arancia)",
  },
  {
    name : "Kir",
    ingredients: "(Vino Bianco, Creme de Cassis)",
  },
  {
    name : "Kir Royale",
    ingredients: "(Prosecco, Creme de Cassis)",
  },
  {
    name : "Pina Colada",
    ingredients: "(Kingston White Rum, Succo D’Ananas, Purea di Cocco)",
  },
  {
    name : "Pimm’s Cup",
    ingredients: "(Pimm’s, Limonata)",
  },
  {
    name : "Whiskey Sour",
    ingredients: "(Whiskey, Succo di Limone, Sciroppo di zucchero, Albume)",
  },
  {
    name : "Paloma",
    ingredients: "(Tequila Espolon, Succo di Limone, Sciroppo d’Agave, Top Soda al Pompelmo)",
  },
  {
    name : "Three Miles",
    ingredients: "(Rum Kingston Bianco, Cognac, Succo di Limone, Granatina)",
  },
  {
    name : "Champagne Cocktail",
    ingredients: "(Cognac, Grand Marnier, Zucchero, Angostura Bitter, top Prosecco)",
  },
  {
    name : "De La Louisiane",
    ingredients: "(Wild Turkey Bourbon, Carpano Rosso, Dom Benedectine, Assenzio, Peychaud bitter)",
  },
  {
    name : "Old Fashioned",
    ingredients: " (Wild Turkey Bourbon, Zucchero, Angostura Bitter)",
  },
];

export default cocktailClassicList;