const vodkaList = [
  {
    name: "Neft",
    price: "8.00"
  },
  {
    name: "Belvedere",
    price: "8.00"
  },
  {
    name: "Beluga",
    price: "8.00"
  },
  {
    name: "Greygoose",
    price: "8.00"
  },
  {
    name: "Skyy",
    price: "6.00"
  },
  {
    name: "Moskowskaya",
    price: "6.00"
  },
];

export default vodkaList;