const cocktailIndustrialSipsList = [
  {
    name: "Industral Chic",
    ingredients: "(Bourbon wiskey, Succo di limone, Liquore agli agrumi ed avocado, Cordial allo zenzero, Angostura bitter)",
  },
  {
    name: "L’arte del recupero",
    ingredients: "(Laphroig 10 anni, Sherry, Martini bitter reserva, Bitter peychaud’s)",
  },
  {
    name: "Scienza dei liquidi",
    ingredients: "(Klima gin rosa, Sciroppo litchy, Succo di limone, Top feever indian tonica)",
  },
  {
    name: "Il gusto dell’industria",
    ingredients: "(Vodka, Mix caffe e liquore alla nocciola, Sciroppo di zucchero)",
  },
  {
    name: "Elettricità inebriante",
    ingredients: "(Vodka, Succo d’arancia, Grapefruit soda, Bitter peychaud’s)",
  },
  {
    name: "Ferro e fuoco",
    ingredients: "(Patron silver affumicata, Liquore red hot, Lime, Grapefruit, Grapefruit soda)",
  },
  {
    name: "Robo-rum punch",
    ingredients: "(Rum scuro, Succo d’arancia, Succo d’ananas, Dry curacao, Liquore al caffe, Sciroppo di zucchero)",
  },
  {
    name: "Meccanismo di precisione ",
    ingredients: "(Vodka, Wild strawberry, Creme de cacao, Heavy cream)",
  },
];

export default cocktailIndustrialSipsList;