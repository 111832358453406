import React from "react";
import { Routes, Route } from "react-router-dom";
import i18next from 'i18next';
import { getLangCodeFromUrl } from "./features/getCodeLang";
import url from './config/url.json';

import Menu from './pages/menu';
import Privacy from './pages/policyPrivacy';

export default function AppRoutes() {
  const languageCode = getLangCodeFromUrl();
  i18next.changeLanguage(languageCode);
  document.documentElement.lang = languageCode;

  return (
    <Routes>
      <Route index element={<Menu/>} />

      { /* it */ }
      <Route path={url.it.menu} element={<Menu/>} />
      <Route path={url.it.privacy} element={<Privacy/>} />

      { /* en */ }
      <Route path={url.en.menu} element={<Menu/>} />
      <Route path={url.en.privacy} element={<Privacy/>} />

      { /* fr */ }
      <Route path={url.fr.menu} element={<Menu/>} />
      <Route path={url.fr.privacy} element={<Privacy/>} />

      { /* de */ }
      <Route path={url.de.menu} element={<Menu/>} />
      <Route path={url.de.privacy} element={<Privacy/>} />

      { /* es */ }
      <Route path={url.es.menu} element={<Menu/>} />
      <Route path={url.es.privacy} element={<Privacy/>} />


      { /* se */ }
      <Route path={url.se.menu} element={<Menu/>} />
      <Route path={url.se.privacy} element={<Privacy/>} />

      { /* Missing page */ }
      <Route path="/*" element={<Menu/>} />
    </Routes>
  );
};