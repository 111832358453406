const draftBeerList = [
  {
    name: "Veltins (Pils)",
    price02: "3.00",
    price04: "5.00",
    price1: "11.00",
    price3: "30.00",
  },
  {
    name: "Lagunitas (Ipa)",
    price02: "4.00",
    price04: "7.00",
    price1: "13.00",
    price3: "40.00",
  },
  {
    name: "Bull dog (Strong Lager)",
    price02: "3.00",
    price04: "6.00",
    price1: "12.00",
    price3: "40.00",
  },
  {
    name: "Oberbräu 1605 (Rossa)",
    price02: "3.00",
    price04: "6.00",
    price1: "12.00",
    price3: "40.00",
  },
];

export default draftBeerList;