import React from 'react';
import { useTranslation } from "react-i18next";

import * as Styles from './styles';
import * as Divider from '../../styles/dividerStyles';

export default function PageFoods() {
  const { t, ready } = useTranslation();

  if (!ready) return "loading translations...";

  const foodList = t('foodList', { returnObjects: true });

  return (
    <Styles.PageMenu id={t('menu-title_food_tag')}>
      <Styles.H2>{t('menu-title_food')}</Styles.H2>

      <p className='text-center'>
        {t('menu-kitchen_schedule')}<br/>
        {t('menu-kitchen_hours')}
      </p>
      <Divider.H10/>

      { foodList.map((item, i) =>
        <CategoryFood key={i} name={item.category} list={item.foods}/>
      )}

      <Divider.H30/>

      <p className='text-center'>
        {t('menu-choice_sauces')}<br/>
        {t('menu-sauces')}<br/>
        {t('menu-info')}
      </p>
    </Styles.PageMenu>
  );
}

const CategoryFood = ({name, list}) => {
  return (
    <>
      <Styles.H3>{name}</Styles.H3>
      { list.map((food, i) =>
        <Row key={i} name={food.name} price={food.price} ingredients={food.ingredients}/>
      )}
      <Divider.H20/>
    </>
  );
}

const Row = ({name, price, ingredients}) => {
  return (
    <Styles.Row>
      <Styles.Name>{name}</Styles.Name>
      <Styles.Price>{price} €</Styles.Price>
      <Styles.Ingredients>{ingredients}</Styles.Ingredients>
    </Styles.Row>
  );
}