import styled from "styled-components";

export const Auto = styled.hr`
  width: 85px;
  margin: 0px auto;
  background: ${({ theme }) => theme.bg3};
`;

export const Small = styled.hr`
  width: 30px;
  margin: 0px auto;
  background: ${({ theme }) => theme.bg3};
`;

export const Full = styled.hr`
  margin: 0px 0px;
  background: ${({ theme }) => theme.bg3};
`;

export const Left = styled.hr`
  width: 85px;
  margin: 0px 0px;
  background: ${({ theme }) => theme.bg3};
`;