const spritzList = [
  {
    name : "Aperol Spritz",
    ingredients: "(Prosecco, Aperol, Soda)",
  },
  {
    name : "Campari Spritz",
    ingredients: "(Prosecco, Bitter Campari, soda)",
  },
  {
    name : "Select Spritz",
    ingredients: "(Prosecco, Select Bitter, Soda)",
  },
];

export default spritzList;