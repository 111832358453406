const craftBeerList = [
  {
    category: "Epica",
    beers: [
      {
        name: "Polifemo (A.P.A)",
        price: "6.00"
      },
      {
        name: "Eolo (Pale Ale)",
        price: "6.00"
      },
      {
        name: "Medusa (Dubbel)",
        price: "6.00"
      }
    ]
  },
  {
    beers: [
      {
        name: "Birra gluten free",
        price: "6.00"
      }
    ]
  },
];

export default craftBeerList;