import styled from "styled-components";
import { HashLink } from 'react-router-hash-link';

export const Background = styled.div`
  height: 100vh;

  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  background-position: center center;
`;

export const Main = styled.main`
  padding: 20px 15px;

  max-height: 100vh;

  overflow-y: auto;

  @media (max-width: 500px) {
    padding: 20px 10px;
  }
`;

export const ContainerMenu = styled.div`
  margin: auto;
  max-width: 1100px;
`;

export const Logo = styled.img`
  margin: auto;
  height: 250px;
`;

export const ContainerBtnLang = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;

  z-index: 1;
  position: fixed;
  top: 15px;
  left: 10px;

  background-color: transparent;
`;
export const LangBtn = styled.a`
  border-radius: 50px;
  padding: 11px 9px;

  text-decoration: none !important;
  cursor: pointer;
  color: black !important;
`;
export const Flag = styled.img`
  position: relative;
  height: 32px;
`;

export const ContainerBtnSocial = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  z-index: 1;
  position: fixed;
  top: 17px;
  right: 25px;

  background-color: transparent;
`;
export const IconBtn = styled.a`
  border-radius: 50px;
  padding: 12px 11px;

  font-size: 22px;
  text-decoration: none !important;
  cursor: pointer;
  color: black !important;
`;

export const ContainerIndex = styled.section`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  @media (max-width: 620px) {
    grid-template-columns: 1fr;
  }
`;
export const IndexCard = styled(HashLink)`
  margin: auto;
  width: 100%;
  max-width: 350px;

  display: block;
  text-decoration: none;

  &:hover, &:active {
    text-decoration: none;
  }
`;
export const IndexCardImg = styled.div`
  height: 200px;
  width: 100%

  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  background-position: center center;
`;
export const IndexCardTitle = styled.div`
  position: relative;
  top: 150px;

  padding-left: 8px;
  width: 150px;

  font-size: 1.25rem;
  line-height: 2.5rem;
  background: black;
  font-family: 'Philosopher-Regular';
  color: white;
`;

export const BtnToIndex = styled(HashLink)`
  position: fixed;
  bottom: 15px;
  right: 30px;

  border-radius: 5px;
  border: 1px solid;
  padding: 1rem 1.5rem;

  font-size: 1rem;
  font-family: OpenSans-SemiBold;
  text-decoration: none !important;
  cursor: pointer;
  background-color: ${({ theme }) => theme.btnBgPrimary};
  border-color: ${({ theme }) => theme.btnBorderPrimary};
  color: white !important;

  &:hover {
    background-color: ${({ theme }) => theme.btnBgPrimaryHover};
    border-color: ${({ theme }) => theme.btnBorderPrimaryHover};
  }
`;

export const PageMenu = styled.div`
  padding: 25px 15px;

  background-color: white;

  @media (max-width: 620px) {
    padding: 25px 8px;
  }

  @media (max-width: 500px) {
    padding: 25px 4px;
  }
`;

export const H1 = styled.h1`
  text-align: center;
  font-family: 'Philosopher-Bold';
`;

export const H2 = styled.h2`
  margin-bottom: 25px;

  text-align: center;
  font-size: 2.75rem;
  font-family: 'Philosopher-Bold';
`;

export const H3 = styled.h3`
  margin-bottom: 15px;

  text-align: center;
  font-size: 1.8rem;
  font-family: 'Philosopher-Bold';
  text-decoration: underline;
`;

export const H4 = styled.h4`
  margin-bottom: 15px;

  text-align: center;
  font-size: 1.25rem;
  font-family: 'Philosopher-Bold';
`;

export const Row = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "name price" "ingredients ingredients";
  align-items: center;

  margin-bottom: 25px;
`;
export const Name = styled.div`
  grid-area: name;

  font-size: 1.1rem;
`;
export const Ingredients = styled.i`
  grid-area: ingredients;

  font-size: 0.9rem;
`;
export const Price = styled.strong`
  grid-area: price;

  text-align: right;
  font-size: 0.95rem;
  color: black
`;

export const UlAllergenes = styled.ul`
  list-style-type: decimal;
  padding-inline-start: 30px;
`;

export const MenuPageDivider = styled.hr`
  margin: 30px auto;
  width: 85px;
  background: ${({ theme }) => theme.bg3};
`;