const brandyList = [
  {
    category : "Bepi Tosolini",
    drinks: [
      {
        name: "Amarone",
        price: "7.00"
      },
      {
        name: "Barolo",
        price: "7.00"
      },
      {
        name: "Brunello",
        price: "7.00"
      },
      {
        name: "Ciliegio",
        price: "6.00"
      },
      {
        name: "Chardonay",
        price: "10.00"
      },
    ]
  },
  {
    category : "Caffo",
    drinks: [
      {
        name: "Barrique",
        price: "5.00"
      },
    ]
  },
  {
    category : "Cognac",
    drinks: [
      {
        name: "Martell V.S.",
        price: "5.00"
      },
      {
        name: "Rémy Martin V.S.O.P.",
        price: "10.00"
      },
      {
        name: "Camus",
        price: "8.00"
      },
      {
        name: "Courvoisier V.S.",
        price: "6.00"
      },
    ]
  },
  {
    category : "Rum",
    drinks: [
      {
        name: "Diplomatico Reserva Esclusiva",
        price: "9.00"
      },
      {
        name: "Diplomatico Reserva de Familia",
        price: "10.00"
      },
      {
        name: "Diplomatico Mantuano",
        price: "7.00"
      },
      {
        name: "Zacapa 23",
        price: "8.00"
      },
      {
        name: "Legendario Elixir",
        price: "6.00"
      },
      {
        name: "Botran 15 anni reserva",
        price: "8.00"
      },
      {
        name: "Botran Rare Blend",
        price: "12.00"
      },
      {
        name: "Botran 18 anni solera",
        price: "9.00"
      },
      {
        name: "Botran Cobre spiced",
        price: "10.00"
      },
      {
        name: "Botran Reserva Blanca",
        price: "8.00"
      },
    ]
  },
];

export default brandyList;