const winList = [
  {
    brand: "Feudo arancio",
    list: [
      {
        wines: [
          {
            name: "Tinchitè",
            glass: "5.00",
            bottle: "20.00",
          }
        ]
      }
    ]
  },
  {
    brand: "Fina",
    list: [
      {
        category: "Bianco / White / Blanc",
        wines: [
          {
            name: "Kikè",
            glass: "5.00",
            bottle: "25.00",
          },
          {
            name: "Makise",
            glass: "5.00",
            bottle: "25.00",
          },
        ]
      },
    ]
  },
  {
    brand: "Firriato",
    list: [
      {
        wines: [
          {
            name: "Sant'Agostino",
            glass: "5.00",
            bottle: "25.00",
          },
        ]
      }
    ]
  },
  {
    brand: "Mandrarossa",
    list: [
      {
        wines: [
          {
            name: "Calamossa",
            glass: "5.00",
            bottle: "20.00",
          }
        ]
      }
    ]
  },
  {
    brand: "Milazzo",
    list: [
      {
        wines: [
          {
            name: "Bianco di nera",
            glass: "5.00",
            bottle: "24.00",
          }
        ]
      }
    ]
  },
  {
    brand: "Morgante",
    list: [
      {
        wines: [
          {
            name: "Nero d'avola",
            glass: "5.00",
            bottle: "22.00",
          },
        ]
      }
    ]
  },
  {
    brand: "Tasca d’Almerita",
    list: [
      {
        category: "Bianco / White / Blanc",
        wines: [
          {
            name: "Leone",
            glass: "5.00",
            bottle: "25.00",
          },
          {
            name: "Cavallo delle fate grillo",
            glass: "5.00",
            bottle: "24.00",
          },
          {
            name: "Cavallo delle fate catarratto",
            glass: "5.00",
            bottle: "24.00",
          },
          {
            name: "Buonora (etna bianco)",
            bottle: "30.00",
          }
        ]
      },
      {
        category: "Rosso / Red / Rouge",
        wines: [
          {
            name: "L’amuri nero d’avola",
            glass: "5.00",
            bottle: "24.00",
          },
          {
            name: "Gaia nera (etna rosso)",
            bottle: "30.00",
          }
        ]
      }
    ]
  },
  {
    brand: "Tenute Orestiadi",
    list: [
      {
        category: "Bianco / White / Blanc",
        wines: [
          {
            name: "Nissim ",
            glass: "5.00",
            bottle: "24.00",
          },
          {
            name: "Grillo bio",
            glass: "5.00",
            bottle: "25.00",
          },
        ]
      },
      {
        category: "Rosso / Red / Rouge",
        wines: [
          {
            name: "Perricone",
            glass: "5.00",
            bottle: "24.00",
          },
          {
            name: "Frappato",
            glass: "5.00",
            bottle: "24.00",
          }
        ]
      }
    ]
  },
];

export default winList;