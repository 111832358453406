import React from 'react';
import { useTranslation } from "react-i18next";

import * as Styles from './styles';
import * as Divider from '../../styles/dividerStyles';

import draftBeerList from '../../menu/draftBeerList';
import craftBeerList from '../../menu/craftBeerList';

export default function PageBeer() {
  const { t } = useTranslation();

  return (
    <Styles.PageMenu id={t('menu-title_beer_tag')}>
      <Styles.H2>{t('menu-title_beer')}</Styles.H2>

      <DraftBeer/>
      <Divider.H50/>
      <CraftBeer/>
    </Styles.PageMenu>
  );
}

const DraftBeer = () => {
  const { t } = useTranslation();

  return (
    <>
      <Styles.H3>{t('menu-draft_beer')}</Styles.H3>

      <table>
        <thead>
          <tr>
            <th/>
            <th style={{textAlign: "right"}}>0.20 cl</th>
            <th style={{textAlign: "right"}}>0.40 cl</th>
            <th style={{textAlign: "right"}}>1 l</th>
            <th style={{textAlign: "right"}}>3 l</th>
          </tr>
        </thead>

        <tbody>
        { draftBeerList.map((beer, i) =>
          <tr key={i}>
            <td>{beer.name}</td>
            <td style={{ textAlign: "right", color: "black" }}><strong>{beer.price02} €</strong></td>
            <td style={{ textAlign: "right", color: "black" }}><strong>{beer.price04} €</strong></td>
            <td style={{ textAlign: "right", color: "black" }}><strong>{beer.price1} €</strong></td>
            <td style={{ textAlign: "right", color: "black" }}><strong>{beer.price3} €</strong></td>
          </tr>
        )}
        </tbody>
      </table>
    </>
  );
}

const CraftBeer = () => {
  const { t } = useTranslation();

  return (
    <>
      <Styles.H3>{t('menu-craft_beer')}</Styles.H3>

      { craftBeerList.map((item, i) =>
        <div key={i}>
          <div className='clearfix'>
            <Styles.H4>{item.category}<span style={{float: "right"}}> 0.33 cl</span></Styles.H4>
          </div>

          <Divider.H10/>

          { item.beers.map((beer, i) =>
            <Row key={i} name={beer.name} price={beer.price}/>
          )}

          <Divider.H10/>
        </div>
      )}
    </>
  );
}

const Row = ({name, price, ingredients}) => {
  return (
    <Styles.Row>
      <Styles.Name>{name}</Styles.Name>
      <Styles.Price>{price} €</Styles.Price>
      <Styles.Ingredients>{ingredients}</Styles.Ingredients>
    </Styles.Row>
  );
}