import languages from "./languages";
import * as en from '../assets/locales/en/translation.json';
import * as fr from '../assets/locales/fr/translation.json';
import * as it from '../assets/locales/it/translation.json';
import * as de from '../assets/locales/de/translation.json';
import * as es from '../assets/locales/es/translation.json';
import * as se from '../assets/locales/se/translation.json';

const i18InitConfig = {
  supportedLngs: languages,
  fallbackLng: "it",
  detection: {
    order: ['path', 'cookie', 'htmlTag'],
    caches: ['cookie'],
  },
  resources: {
    en: { translation: en },
    fr: { translation: fr },
    it: { translation: it },
    de: { translation: de },
    es: { translation: es },
    se: { translation: se },
  },
  react: { useSuspense: true }
}

export default i18InitConfig;